/**
 * Tags to send form data to HubSpot integration on submission
 */

import React from 'react'
import Helmet from 'react-helmet'

const HubSpotFormTag = ({
  formId,
}: {
  formId?: string
}) => {
  if (process.env.NODE_ENV !== 'production') return null;

  const ieComment = '<!--[if lte IE 8]><script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2-legacy.js"></script><![endif]-->';

  return (
    <Helmet>
      <meta dangerouslySetInnerHTML={{ __html: ieComment }}></meta>
      <script charSet="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2.js"></script>
      <script>
        {`hbspt.forms.create({
          region: "${process.env.GATSBY_HUBSPOT_REGION}",
          portalId: "${process.env.GATSBY_HUBSPOT_TRACKING_ID}",
          formId: "${formId}"
        });`}
      </script>
    </Helmet>
  )
}

export default HubSpotFormTag
