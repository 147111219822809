import React from 'react'
import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import Fade from 'react-reveal/Fade'

import Theme, { Mq } from '../themes/default'
import ContactUsForm from '../molecules/contact-us-form'
import Layout from '../organisms/layout'
import HubSpotFormTag from '../organisms/hubSpotFormTag'
import { SubpageHero } from '../molecules/subpage-hero'
import SEO from '../organisms/seo'

const NewsletterSignupFormContainer = styled.div`
  padding: 3rem 5%;
  height: 100%;
  overflow: hidden;

  ${Mq.md} {
    padding: 0 3% 6rem;
    margin: 0 5%;
  }
  ${Mq.xxl} {
    margin: 3% 12% 0;
  }
`

const NewsletterSignup = () => {
  const onSubmission = () => navigate('/thank-you')

  return (
    <Layout hideNavItems>
      <SEO description="BiasSync Newsletter Signup" title="Newsletter Signup" />
      <HubSpotFormTag formId={process.env.GATSBY_HUBSPOT_NEWSLETTER_SIGNUP_FORM_ID} />
      <SubpageHero
        title="Sign up for our newsletter!"
        text="Sign up to receive interesting content about mitigating bias and cutting edge unconscious bias research."
      />
      <Fade bottom={true} cascade={true} distance={Theme.fadeDistance}>
        <NewsletterSignupFormContainer>
          <ContactUsForm onSubmission={onSubmission} contactType="newsletter" ctaButtonText="Sign up" />
        </NewsletterSignupFormContainer>
      </Fade>
    </Layout>
  )
}

export default NewsletterSignup
