import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import {
  Formik,
  FormikActions,
  FormikErrors,
  FormikProps,
  Field,
  FieldProps,
} from 'formik'

import { IUTMParams } from '../interfaces/app'
import { AppContext } from '../app-context'
import { Row, Column } from '../atoms/grid'
import { Input } from '../atoms/input'
import { Button } from '../atoms/link'
import { Select } from '../atoms/select'
import Theme, { Mq } from '../themes/default'

const FormWrapper = styled.div`
  width: 85%;
  margin: 0 auto;
  z-index: 1;
`

const InputField = styled(Input)`
  font-size: 1rem;
  padding: 0.75rem;
  width: 100%;
  border-width: 1px;
  color: ${Theme.colors.darkBlue};
  border-color: ${rgba(Theme.colors.darkBlue, 0.1)};

  &::placeholder {
    color: ${rgba(Theme.colors.darkBlue, 0.4)};
  }

  ${Mq.md} {
    font-size: 1.2rem;
    padding: 0.9rem;
  }
`

const Error = styled.div`
  color: #cc0000;
  margin-top: 1rem;
  height: 2rem;
`

const StyledButton = styled(Button)`
  margin: 0 auto;
`

const HelperText = styled.p`
  width: 100%;
  padding: 1.2rem 0.8rem;
  margin: 0;
  font-size: 1rem;
  color: ${rgba(Theme.colors.darkBlue, 0.5)};
`

const SelectFieldWrapper = styled.div`
  &::after {
    margin-left: -3em;
    font-size: 0.75em;
    pointer-events: none;
    content: '▼';
    color: ${Theme.colors.darkBlue};
  }
`;

const SelectField = styled(Select)<ISelectFieldProps>`
  appearance: none;
  font-size: 1rem;
  padding: 0.75rem;
  width: 100%;
  border-width: 1px;
  border-color: ${rgba(Theme.colors.darkBlue, 0.1)};

  color: ${p => (p.isSelected ? `${Theme.colors.darkBlue}` : `${rgba(Theme.colors.darkBlue, 0.4)}`)};

  ${Mq.md} {
    font-size: 1.2rem;
    padding: 0.9rem;
  }
`

interface ISelectFieldProps {
  isSelected: boolean;
}

interface IFormValues {
  company: string
  companySize: string
  email: string
  firstName: string
  lastName: string
  phone: string
}

const validateForm = (values: IFormValues) => {
  const errors: FormikErrors<IFormValues> = {}

  if (!values.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }

  if (!values.firstName) {
    errors.firstName = 'Required'
  }

  if (!values.lastName) {
    errors.lastName = 'Required'
  }

  if (!values.company) {
    errors.company = 'Required'
  }

  if (!values.phone) {
    errors.phone = 'Required'
  }

  if (!values.companySize) {
    errors.companySize = 'Required'
  }

  return errors
}

const ContactUsForm = ({
  ctaButtonText = 'Submit',
  contactType,
  onSubmission,
}: {
  ctaButtonText?: string,
  contactType: string,
  onSubmission: () => void,
}) => {
  const { appState: { utmParams } } = useContext(AppContext)

  return (
    <FormWrapper>
        <Formik
          initialValues={{
              company: '',
              companySize: '',
              email: '',
              firstName: '',
              lastName: '',
              phone: '',
          }}
          validate={validateForm}
          // tslint:disable: jsx-no-lambda
          onSubmit={(
            values: IFormValues,
            actions: FormikActions<IFormValues>,
          ) => {
            onSubmission()

            try {
              if (typeof window !== 'undefined') {
                window.analytics.track('Contact Form Submitted', { contactType })
              }
            } catch (error) {
              console.log('Analytics Error', error);
            }

            actions.setSubmitting(false)
          }}
          render={(formikBag: FormikProps<IFormValues>) => (
            <form onSubmit={(e: React.FormEvent<HTMLFormElement>) => { e.preventDefault(); formikBag.handleSubmit()}} >
              <HelperText>
                Note: This is not the login form for employees at participating companies/organizations.  Please visit your company’s private site, found in your email.
              </HelperText>
              <Row flexWrap="wrap">
                <Field
                  name="firstName"
                  render={({ field, form }: FieldProps<IFormValues>) => (
                    <Column width={[1, 1, 1 / 2]} p={2}>
                      <InputField type="text" placeholder="First name" {...field} />
                      <Error>
                        {form.touched.firstName &&
                          form.errors.firstName &&
                          form.errors.firstName}
                      </Error>
                    </Column>
                  )}
                />
                <Field
                  name="lastName"
                  render={({ field, form }: FieldProps<IFormValues>) => (
                    <Column width={[1, 1, 1 / 2]} p={2}>
                      <InputField type="text" placeholder="Last name" {...field} />
                      <Error>
                        {form.touched.lastName &&
                          form.errors.lastName &&
                          form.errors.lastName}
                      </Error>
                    </Column>
                  )}
                />
              </Row>
              <Row flexWrap="wrap">
                <Field
                  name="company"
                  render={({ field, form }: FieldProps<IFormValues>) => (
                    <Column width={[1, 1, 1 / 2]} p={2}>
                      <InputField
                        type="text"
                        placeholder="Company"
                        {...field}
                      />
                      <Error>
                        {form.touched.company &&
                          form.errors.company &&
                          form.errors.company}
                      </Error>
                    </Column>
                  )}
                />
                <Field
                  name="email"
                  render={({ field, form }: FieldProps<IFormValues>) => (
                    <Column width={[1, 1, 1 / 2]} p={2}>
                      <InputField
                        type="text"
                        placeholder="Email Address"
                        {...field}
                      />
                      <Error>
                        {form.touched.email &&
                          form.errors.email &&
                          form.errors.email}
                      </Error>
                    </Column>
                  )}
                />
              </Row>

              <Row flexWrap="wrap">
                <Field
                  name="companySize"
                  render={({ field, form }: FieldProps<IFormValues>) => (
                    <Column p={2} width={1}>
                      <SelectFieldWrapper>
                        <SelectField isSelected={!!field.value} {...field}>
                          <option selected hidden disabled value="" label="Please select your company size" />
                          <option value="<50" label="<50" />
                          <option value="51-250" label="51-250" />
                          <option value="251-1000" label="251-1000" />
                          <option value="1001-5000" label="1001-5000" />
                          <option value="5001-10000" label="5001-10000" />
                          <option value="10k+" label="10k+" />
                        </SelectField>
                      </SelectFieldWrapper>
                      <Error>
                        {form.touched.companySize &&
                          form.errors.companySize &&
                          form.errors.companySize}
                      </Error>
                    </Column>
                  )}
                />
              </Row>
                          
              <Row flexWrap="wrap">
                <Field
                  name="phone"
                  render={({ field, form }: FieldProps<IFormValues>) => (
                    <Column p={2} width={1}>
                      <InputField
                        type="text"
                        placeholder="Phone Number"
                        {...field}
                      />
                      <Error>
                        {form.touched.phone &&
                          form.errors.phone &&
                          form.errors.phone}
                      </Error>
                    </Column>
                  )}
                />
              </Row>

              {Object.keys(utmParams).map(utmKey => <input type="hidden" id={utmKey} name={utmKey} value={utmParams[utmKey as keyof IUTMParams]}></input>)}
  
              <Row mt={3} mb={3}>
                <StyledButton
                  type="submit"
                  disabled={!formikBag.isValid || formikBag.isSubmitting}
                >
                  {ctaButtonText}
                </StyledButton>
              </Row>
            </form>
          )}
        />
    </FormWrapper>
  )
}

export default ContactUsForm